const endPoint = process.env.REACT_APP_endPoint + "/managers" || 'https://assetmanagement.fesg.ldit.be/api/v1/managers';

export async function getSites(page, sortBy, asc, token, setToken, setUser) {
    const url = new URL(endPoint + '/sites');

    let search = {
        sortBy,
        asc
    }

    if (page !== null && page !== undefined) {
        search.page = page;

    }

    url.search = new URLSearchParams(search);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getSite(siteId, token, setToken, setUser) {
    const url = new URL(endPoint + '/sites/' + siteId);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getMaxPage(token, setToken, setUser) {
    const url = new URL(endPoint + '/sites/maxPage');

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function createSite(name, description, token, setToken, setUser) {
    const url = new URL(endPoint + '/sites');

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            name,
            description
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}
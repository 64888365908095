import React, {useState} from "react";

function AddChecklist({assetId: asset, addChecklist}) {
    const [type, setType] = useState("");

    async function submit(evt) {
        evt.preventDefault();
        window.$('#addChecklistModal' + asset).modal('hide');
        window.$('#addChecklistModal' + asset).on('hidden.bs.modal', async (e) => {
            window.$('#addChecklistModal' + asset).off('hidden.bs.modal');
            await addChecklist(asset, type);
        });
    }

    return (
        <div>
            <button type={"button"} className={"btn btn-sm btn-success"} data-toggle={"modal"} data-target={"#addChecklistModal" + asset}>Add a checklist</button>
            <div className="modal fade" id={"addChecklistModal" + asset}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={submit}>
                        <div className="modal-header">
                            <h5 className="modal-title">Add a checklist</h5>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="type">Type:</label>
                                <select className={"form-control"} value={type} onChange={(evt) => {
                                    setType(evt.target.value);
                                }} required={true} name="type" id="type">
                                    <option value="">Select a type</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="yearly">Yearly</option>
                                    <option value="custom">Custom</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type={"submit"} className={"btn btn-success"}>Add checklist</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddChecklist;
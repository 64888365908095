import React, {Fragment, useContext, useEffect, useState} from "react";
import {getSites} from "../models/sites";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import {AuthContext} from "../../contexts/AuthContext";

function AddBuilding({addBuilding, siteId: siteIdFromParent}) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [siteId, setSiteId] = useState(siteIdFromParent ? siteIdFromParent.toString(): "");
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sites, setSites] = useState([]);

    const {token, setToken, setUser} = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            if (!siteIdFromParent) {
                await fetchSites();
            }
        })();
    }, []);

    async function fetchSites() {
        setErrors([]);
        setLoading(true);

        try {
            const response = await getSites(null, 'name', true, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setSites(response.sites);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch sites."]);
        }
        setLoading(false);
    }

    function submit(evt) {
        evt.preventDefault();
        window.$('#addBuildingModal').modal('hide');
        window.$('#addBuildingModal').on('hidden.bs.modal', async (e) => {
            window.$('#addBuildingModal').off('hidden.bs.modal');
            await addBuilding(name, siteId, description);
        });
    }

    return (
        <div>
            <button type={"button"} className={"btn btn-success"} data-toggle={"modal"} data-target="#addBuildingModal">Add a building</button>
            <div className="modal fade" id="addBuildingModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={submit}>

                            <div className="modal-header">
                                <h5 className="modal-title">Add a building</h5>
                                <button type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {errors.map((error, index) => <Error key={index} message={error} />)}
                                <div className="form-row">
                                    <div className="form-group col">
                                        <label htmlFor="name">Name:</label>
                                        <input type="text" id={"name"} value={name} className={"form-control"} required={true} placeholder={"Building name"} onChange={(evt) => {
                                            setName(evt.target.value);
                                        }}/>
                                    </div>
                                    {!siteIdFromParent &&  <div className="form-group col">
                                        {loading ? <Spinner />:
                                            <Fragment>
                                                <label htmlFor="site">Site:</label>
                                                <select id="site" value={siteId} onChange={(evt) => {
                                                    setSiteId(evt.target.value);
                                                }} className={"form-control"} required={true}>
                                                    <option value="">Choose a site</option>
                                                    {sites.map((site) => {
                                                        return <option value={site.id} key={site.id}>{site.name}</option>
                                                    })}
                                                </select>
                                            </Fragment>}

                                    </div>}

                                </div>

                                <div className="form-group">
                                    <label htmlFor="description">Description:</label>
                                    <textarea id={"description"} value={description} className={"form-control"} required={false} placeholder={"Site description"} onChange={(evt) => {
                                        setDescription(evt.target.value);
                                    }}/>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type={"submit"} className={"btn btn-success"}>Add building</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBuilding;
import React, {Fragment, useContext, useEffect, useState} from "react";
import AddTechnician from "./AddTechnician";
import ViewTechnicians from "./ViewTechnicians";
import TechniciansOne from "./TechniciansOne";
import {Switch, Route} from "react-router-dom";
import Pagination from "../../components/Pagination";
import {getTechnicians, getMaxPage, createTechnician} from "../models/technicians";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import {SortContext} from "../../contexts/SortContext";

function TechniciansAll() {
    const [technicians, setTechnicians] = useState([]);
    const [maxPage, setMaxPage] = useState(0);
    const [page, setPage] = useState(0);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState("createdAt");
    const [asc, setAsc] = useState(true);

    const {token, setToken, setUser} = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            await fetchMaxPage();
        })();
    }, []);

    useEffect(() => {
        (async() => {
            await fetchTechnicians();
        })();
    }, [page, sortBy, asc]);

    async function fetchMaxPage() {
        setErrors([]);

        try {
            const response = await getMaxPage(token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setMaxPage(response.maxPage);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to get max page."]);
        }
    }
    async function fetchTechnicians() {
        setErrors([]);
        setLoading(true);
        try {
            const response = await getTechnicians(page, sortBy, asc, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setTechnicians(response.technicians);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch technicians."]);
        }
        setLoading(false);
    }

    async function addTechnician(firstName, lastName, email, description) {
        setErrors([]);
        setLoading(true);

        try {
            const response = await createTechnician(firstName, lastName, email, description, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                await fetchMaxPage();
                await fetchTechnicians();
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to create technician."]);
        }

        setLoading(false);
    }

    return (
        <SortContext.Provider value={{
            sortBy: sortBy,
            asc: asc,
            setSortBy: setSortBy,
            setAsc: setAsc
        }}>
            <div className={"mx-auto mt-2 col"} style={{maxHeight: '95%', overflow: "auto"}}>
                {errors.map((error, index) => <Error key={index} message={error} />)}

                <Switch>
                    <Route exact={true} path={'/technicians'}>
                        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
                            <AddTechnician addTechnician={addTechnician}/>

                            {loading ? <Spinner /> :
                                <Fragment>
                                    <ViewTechnicians technicians={technicians}/>
                                    <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                                </Fragment>
                            }
                        </div>
                    </Route>
                    <Route path={'/technicians/:technicianId'}>
                        <TechniciansOne />
                    </Route>
                </Switch>
            </div>
        </SortContext.Provider>

    );
}

export default TechniciansAll;
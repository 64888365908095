import React, {Fragment, useContext, useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import {createChecklist} from "../models/checklists";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import ViewAssets from "./ViewAssets";
import {SortContext} from "../../contexts/SortContext";
import {getAssets} from "../models/assets";

function MyMaintenanceAll() {
    const [assets, setAssets] = useState([]);
    const [errors, setErrors] = useState([0]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState("createdAt");
    const [asc, setAsc] = useState(true);

    const history = useHistory();

    const {token, setToken, setUser} = useContext(AuthContext);

    useEffect(() => {
        (async() => {
            await fetchAssets();
        })();
    }, [sortBy, asc]);


    async function fetchAssets() {
        setErrors([]);
        setLoading(true);
        try {
            const response = await getAssets(null, null, sortBy, asc, true, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setAssets(response.assets);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch your maintenances."]);
        }
        setLoading(false);
    }

    async function addChecklist(assetId, type) {
        setErrors([]);
        setLoading(true);

        try {
            const response = await createChecklist(assetId, type, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                const checklistId = response.checklist.id;
                history.push('/checklists/' + checklistId);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to create checklist."]);
        }

        setLoading(false);
    }

    return (
        <SortContext.Provider value={{
            sortBy: sortBy,
            asc: asc,
            setSortBy: setSortBy,
            setAsc: setAsc
        }}>
            <div className={"col mx-auto mt-2"} style={{maxHeight: '95%', overflow: "auto"}}>
                {errors.map((error, index) => <Error key={index} message={error} />)}

                <Switch>
                    <Route path={'/myMaintenance'}>
                        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
                            {loading ? <Spinner /> :
                                <Fragment>
                                    <ViewAssets assets={assets} addChecklist={addChecklist}/>
                                </Fragment>
                            }
                        </div>
                    </Route>
                </Switch>
            </div>

        </SortContext.Provider>
    );
}

export default MyMaintenanceAll;
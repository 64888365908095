import React, {Fragment, useContext, useEffect, useState} from "react";
import {getAsset, getCheckListItems, updateTechnicianId} from "../models/assets";
import {useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import CheckListGroupRow from "./CheckListGroupRow";
import QuestionRow from "./QuestionRow";
import {getTechnicians} from "../models/technicians";

function EditAsset() {
    const [asset, setAsset] = useState({});
    const [errors, setErrors] = useState([]);
    const [checkListGroups, setCheckListGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [technicians, setTechnicians] = useState([]);
    const [technician, setTechnician] = useState("");

    const {token, setToken, setUser} = useContext(AuthContext);

    const {assetId} = useParams();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchAsset();
            await fetchCheckListQuestions();
            await fetchTechnicians();
            setLoading(false);
        })();
    }, []);

    async function fetchAsset() {
        try {
            const response = await getAsset(assetId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors((prevState) => {
                    return [...prevState, response.errors];
                });
            }
            else {
                setAsset(response.asset);
                setTechnician(response.asset.technicianId === null ? "": response.asset.technicianId);
            }
        }
        catch (e) {
            console.error(e);
            setErrors((prevState) => {
                const newState = [...prevState];
                newState.push("Failed to reach back end to fetch asset.")
                return newState;
            });
        }
    }

    async function fetchCheckListQuestions() {
        try {
            const response = await getCheckListItems(assetId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors((prevState) => {
                    return [...prevState, response.errors];
                });
            }
            else {
                setCheckListGroups(response.checkListItems);
            }
        }
        catch (e) {
            setErrors((prevState) => {
                const newState = [...prevState];
                newState.push("Failed to reach back end to fetch checklist items.")
                return newState;
            });
        }
    }

    async function changeTechnician(evt) {
        setErrors([]);
        let technicianId = evt.target.value;

        try {
            const response = await updateTechnicianId(assetId, technicianId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors((prevState) => {
                    return [...prevState, response.errors];
                });
            }
            else {
                setTechnician(technicianId);
            }
        }
        catch (e) {
            console.error(e);
            setErrors((prevState) => {
                const newState = [...prevState];
                newState.push("Failed to reach back end to update the technician.")
                return newState;
            });
        }
    }

    async function fetchTechnicians() {
        try {
            const response = await getTechnicians(null, "firstName", true, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors((prevState) => {
                    return [...prevState, response.errors];
                });
            }
            else {
                setTechnicians(response.technicians);
            }
        }
        catch (e) {
            console.log(e);
            setErrors((prevState) => {
                const newState = [...prevState];
                newState.push("Failed to reach back end to fetch technicians.")
                return newState;
            });
        }
    }


    /*async function changeShow(evt) {
        setShow(evt.target.value);
        setLoading(true);
        setErrors([]);

        let newCheckListGroups = [...checkListGroups];

        try {
            switch (evt.target.value) {
                case "all":
                    for (let i = 0; i < checkListGroups.length; i++) {
                        for (let j = 0; j < checkListGroups[i].subGroups.length; j++) {
                            const {id, show} = checkListGroups[i].subGroups[j];
                            if (!show) {
                                await updateCheckListItem(id, true, token, setToken, setUser);
                                newCheckListGroups[i].subGroups[j].show = true;
                            }
                        }
                    }
                    break;
                case "weekly":
                    for (let i = 0; i < checkListGroups.length; i++) {
                        for (let j = 0; j < checkListGroups[i].subGroups.length; j++) {
                            const {id, show, weekly} = checkListGroups[i].subGroups[j];
                            if (!show && weekly) {
                                await updateCheckListItem(id, true, token, setToken, setUser);
                                newCheckListGroups[i].subGroups[j].show = true;
                            }
                            else if (show && !weekly) {
                                await updateCheckListItem(id, false, token, setToken, setUser);
                                newCheckListGroups[i].subGroups[j].show = false;
                            }
                        }
                    }
                    break;
                case "yearly":
                    for (let i = 0; i < checkListGroups.length; i++) {
                        for (let j = 0; j < checkListGroups[i].subGroups.length; j++) {
                            const {id, show, yearly} = checkListGroups[i].subGroups[j];
                            if (!show && yearly) {
                                await updateCheckListItem(id, true, token, setToken, setUser);
                                newCheckListGroups[i].subGroups[j].show = true;
                            }
                            else if (show && !yearly) {
                                await updateCheckListItem(id, false, token, setToken, setUser);
                                newCheckListGroups[i].subGroups[j].show = false;
                            }
                        }
                    }
                    break;
                default:
                    break;
            }

            setCheckListGroups(newCheckListGroups);
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to update checklist items."]);
        }

        setLoading(false);
    }
*/

    return (
        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
            <h1>{asset.name}</h1>
            {errors.map((error, index) => <Error key={index} message={error} />)}

            {loading ? <Spinner /> :
                <Fragment>
                    <div className="form-group">
                        <label htmlFor="technician">Technician:</label>
                        <select className={"form-control"} name="technician" id="technician" value={technician} onChange={changeTechnician}>
                            <option value="">All</option>
                            {technicians.map(value => {
                                return <option value={value.id} key={value.id}>{value.firstName} {value.lastName}</option>
                            })}
                        </select>
                    </div>

                    <div className={"table-responsive"} style={{
                        borderRadius: '10px'
                    }} >
                        <table className={"table table-sm table-light table-striped"}>
                            <caption>Checklist items</caption>
                            <thead className={"text-light"}>
                            <tr style={{background: '#6d7fcc'}}>
                                <th>Component</th>
                                <th>Task</th>
                                <th>Weekly</th>
                                <th>Yearly</th>
                                <th>Custom</th>
                            </tr>
                            </thead>
                            <tbody>
                            {checkListGroups.map((group, groupIndex) => {
                                return (
                                    <Fragment key={groupIndex}>
                                        <CheckListGroupRow checkListGroup={group.name}/>
                                        {group.subGroups.map((row, rowIndex) => {
                                            return (
                                                <QuestionRow question={row.question} custom={row.custom} subGroup={row.subGroup} weekly={row.weekly} yearly={row.yearly} id={row.id} key={rowIndex}/>
                                            )
                                        })}
                                    </Fragment>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>

                </Fragment>
            }
        </div>
    );
}

export default EditAsset;
import React, {Fragment, useContext, useEffect, useState} from "react";
import AddBuilding from "./AddBuilding";
import ViewBuildings from "./ViewBuildings";
import {Route, Switch} from "react-router-dom";
import BuildingsOne from "./BuildingsOne";
import Pagination from "../../components/Pagination";
import {AuthContext} from "../../contexts/AuthContext";
import {createBuilding, getMaxPage, getBuildings} from "../models/buildings";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import {SortContext} from "../../contexts/SortContext";

function BuildingsAll() {
    const [buildings, setBuildings] = useState([]);
    const [maxPage, setMaxPage] = useState(0);
    const [page, setPage] = useState(0);
    const [errors, setErrors] = useState([0]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState("createdAt");
    const [asc, setAsc] = useState(true);

    const {token, setToken, setUser, cumulio} = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchMaxPage();
            setLoading(false);
        })();
        window.Cumulio.addDashboard({
            dashboardId: '9af461fd-3162-44dd-a21d-dea1fcc323f2',
            container: '#buildingsDashboard',
            key: cumulio.id,
            token: cumulio.token
        });
    }, []);

    useEffect(() => {
        (async() => {
            await fetchBuildings();
        })();
    }, [page, sortBy, asc]);

    async function fetchMaxPage() {
        setErrors([]);

        try {
            const response = await getMaxPage(null, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setMaxPage(response.maxPage);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to get max page."]);
        }
    }
    async function fetchBuildings() {
        setErrors([]);
        setLoading(true);
        try {
            const response = await getBuildings(null, page, sortBy, asc, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setBuildings(response.buildings);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch buildings."]);
        }
        setLoading(false);
    }

    async function addBuilding(name, siteId, description) {
        setErrors([]);
        setLoading(true);

        try {
            const response = await createBuilding(name, siteId, description, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                await fetchMaxPage();
                await fetchBuildings();
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to create building."]);
        }

        setLoading(false);
    }

    return (
        <SortContext.Provider value={{
            sortBy: sortBy,
            asc: asc,
            setSortBy: setSortBy,
            setAsc: setAsc
        }}>
            <div className={"col mx-auto mt-2"} style={{maxHeight: '95%', overflow: "auto"}}>
                {errors.map((error, index) => <Error key={index} message={error} />)}
                <div id={"buildingsDashboard"}></div>

                <Switch>
                    <Route exact={true} path={'/buildings'}>
                        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
                            <AddBuilding addBuilding={addBuilding}/>

                            {loading ? <Spinner /> :
                                <Fragment>
                                    <ViewBuildings buildings={buildings}/>
                                    <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                                </Fragment>
                            }
                        </div>
                    </Route>
                    <Route path={'/buildings/:buildingId'}>
                        <BuildingsOne />
                    </Route>
                </Switch>

            </div>
        </SortContext.Provider>

    );
}

export default BuildingsAll;
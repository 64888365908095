import React from "react";
import SortableHeader from "../../components/SortableHeader";
import AddChecklist from "./AddChecklist";

function ViewAssets({assets, addChecklist}) {
    return (
        <div className="table-responsive mt-2" style={{
            borderRadius: '10px'
        }} >
            <table className="table table-light table-striped">
                <caption>My maintenances</caption>
                <thead className="text-light" >
                <tr style={{background: '#6d7fcc'}}>
                    <SortableHeader tableName={"site"} text={"Site"} />
                    <SortableHeader tableName={"building"} text={"Building"} />
                    <SortableHeader tableName={"name"} text={"Asset"} />
                    <SortableHeader tableName={"description"} text={"Description"} />
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {assets.map(asset => {
                    return (
                        <tr key={asset.id}>
                            <td>{asset.site}</td>
                            <td>{asset.building}</td>
                            <td>{asset.name}</td>
                            <td>{asset.description}</td>
                            <td><AddChecklist addChecklist={addChecklist} assetId={asset.id} /></td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default ViewAssets;
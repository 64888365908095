import React from "react";
import SortableHeader from "../../components/SortableHeader";
import {Link} from "react-router-dom";

function ViewBuildings({buildings,fromSite = false}) {
    return (
        <div className="table-responsive mt-2" style={{
            borderRadius: '10px'
        }} >
            <table className="table table-light table-striped ">
                <caption>Buildings</caption>
                <thead className="text-light" >
                <tr style={{background: '#6d7fcc'}}>
                    <SortableHeader text={"Name"} tableName={"name"}/>
                    {!fromSite && <SortableHeader text={"Site"} tableName={"site"}/>}
                    <SortableHeader text={"Description"} tableName={"description"}/>
                    <SortableHeader text={"Stamp"} tableName={"createdAt"}/>
                </tr>
                </thead>
                <tbody>
                {buildings.map(building => {
                    return (
                        <tr key={building.id}>
                            <td><Link to={'/buildings/' + building.id}>{building.name}</Link></td>
                            {!fromSite && <td><Link to={'/sites/' + building.siteId}>{building.site}</Link></td>}
                            <td>{building.description}</td>
                            <td>{building.createdAt}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default ViewBuildings;
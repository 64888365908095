import React, {useContext, useEffect, useRef, useState} from "react";
import {getSites} from "../models/sites";
import {getBuildings} from "../models/buildings";
import {getAssets} from "../models/assets";

import Error from "../../components/Error";
import {AuthContext} from "../../contexts/AuthContext";

function AddChecklist({addChecklist}) {
    const [sites, setSites] = useState([]);
    const [site, setSite] = useState("");
    const [buildings, setBuildings] = useState([]);
    const [building, setBuilding] = useState("");
    const [assets, setAssets] = useState([]);
    const [asset, setAsset] = useState("");
    const [type, setType] = useState("");
    const [errors, setErrors] = useState([]);
    const isMounted = useRef(true);

    const {token, setToken, setUser} = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            if (isMounted.current) {
                setErrors([]);
            }
            try {
                const response = await getSites(token, setToken, setUser);
                if (isMounted.current) {
                    if (response.errors.length > 0) {
                        setErrors(response.errors);
                    }
                    else {
                        setSites(response.sites);
                    }
                }
            }
            catch (e) {
                console.error(e);
                if (isMounted.current) {
                    setErrors(["Failed to reach back end to fetch sites."]);
                }
            }
        })();

        return () => {
            isMounted.current = false;
        }
    }, []);

    async function submit(evt) {
        evt.preventDefault();
        window.$('#addChecklistModal').modal('hide');
        window.$('#addChecklistModal').on('hidden.bs.modal', async (e) => {
            window.$('#addChecklistModal').off('hidden.bs.modal');
            await addChecklist(asset, type);
        });
    }

    async function changeSite(evt) {
        setSite(evt.target.value);
        setAssets([]);
        setAsset("");
        setBuilding("");
        setErrors([]);
        try {
            const response = await getBuildings(evt.target.value, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setBuildings(response.buildings);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch buildings."]);
        }
    }

    async function changeBuilding(evt) {
        setBuilding(evt.target.value);
        setAsset("");
        setErrors([]);
        try {
            const response = await getAssets(evt.target.value, null, "name", true, false, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setAssets(response.assets);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch assets."]);
        }
    }

    return (
        <div>
            <button type={"button"} className={"btn btn-success"} data-toggle={"modal"} data-target="#addChecklistModal">Add a checklist</button>
            <div className="modal fade" id="addChecklistModal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={submit}>
                        <div className="modal-header">
                            <h5 className="modal-title">Add a checklist</h5>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {errors.map((error, index) => <Error key={index} message={error} />)}

                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="site">Site:</label>
                                    <select name="site" id="site" required={true} className={"form-control"} value={site} onChange={changeSite}>
                                        <option value="">Select a site</option>
                                        {sites.map((value, index) => {
                                            return <option key={index} value={value.id}>{value.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="form-group col">
                                    <label htmlFor="building">Building:</label>
                                    <select name="building" id="building" required={true} className={"form-control"} value={building} onChange={changeBuilding}>
                                        <option value="">Select a building</option>
                                        {buildings.map((value, index) => {
                                            return <option key={index} value={value.id}>{value.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="form-group col">
                                    <label htmlFor="asset">Asset:</label>
                                    <select name="asset" id="asset" required={true} className={"form-control"} value={asset} onChange={(evt) => {
                                        setAsset(evt.target.value);
                                    }}>
                                        <option value="">Select an asset</option>
                                        {assets.map((value, index) => {
                                            return <option key={index} value={value.id}>{value.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="type">Type:</label>
                                <select className={"form-control"} value={type} onChange={(evt) => {
                                    setType(evt.target.value);
                                }} required={true} name="type" id="type">
                                    <option value="">Select a type</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="yearly">Yearly</option>
                                    <option value="custom">Custom</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type={"submit"} className={"btn btn-success"}>Add checklist</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddChecklist;
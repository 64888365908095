const endPoint = process.env.REACT_APP_endPoint || 'https://assetmanagement.fesg.ldit.be/api/v1';

export async function login (email, password) {
    const response = await fetch(endPoint + '/auth', {
        method: "POST",
        body: JSON.stringify({
            email,
            password
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response.json();
}

export async function resetPassword (token, password) {
    const response = await fetch(endPoint + '/auth/resetPassword', {
        method: "POST",
        body: JSON.stringify({
            token,
            password
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response.json();
}

export async function getUser(token, setToken, setUser) {
    const response = await fetch(endPoint + '/auth', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function getCumulio(token, setToken, setUser) {
    const response = await fetch(endPoint + '/auth/cumulio', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}
import React from "react";

function CheckListGroupRow({checkListGroup}) {
    return (
        <tr style={{backgroundColor: '#7386D5'}} className={"text-light"}>
            <th colSpan={6}>{checkListGroup}</th>
        </tr>
    );
}

export default CheckListGroupRow;
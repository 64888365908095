import React from "react";
import {Link} from "react-router-dom";
import SortableHeader from "../../components/SortableHeader";

function ViewChecklists({checklists, fromAsset = false}) {
    return (
        <div className="table-responsive mt-2" style={{
            borderRadius: '10px'
        }} >
            <table className="table table-light table-striped">
                <caption>Checklists</caption>
                <thead className="text-light" >
                <tr style={{background: '#6d7fcc'}}>
                    <SortableHeader text={"Name"} tableName={"name"}/>
                    <SortableHeader text={"Site"} tableName={"site"}/>
                    <SortableHeader text={"Building"} tableName={"building"}/>
                    {!fromAsset && <SortableHeader text={"Asset"} tableName={"asset"}/>}
                    <SortableHeader text={"Technician"} tableName={"technician"}/>
                    <SortableHeader text={"Status"} tableName={"done"}/>
                    <SortableHeader text={"Stamp"} tableName={"createdAt"}/>
                </tr>
                </thead>
                <tbody>
                {checklists.map(checklist => {
                    return (
                        <tr key={checklist.id}>
                            <td><Link to={'/checklists/' + checklist.id}>{checklist.name}</Link></td>
                            <td><Link to={'/sites/' + checklist.siteId}>{checklist.site}</Link></td>
                            <td><Link to={'/buildings/' + checklist.buildingId}>{checklist.building}</Link></td>
                            {!fromAsset &&  <td><Link to={'/assets/' + checklist.assetId}>{checklist.asset}</Link></td>}
                            <td>{checklist.technician}</td>
                            <td>{checklist.done ? "Finished" : "In progress"}</td>
                            <td>{checklist.createdAt}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default ViewChecklists;
import React from "react";
import CaretRight from "./CaretRight";
import CaretLeft from "./CaretLeft";

function Pagination({maxPage, page, setPage}) {
    function goToPage(evt, pageToGoTo) {
        evt.preventDefault();
        setPage(pageToGoTo);
    }

    return (
        <nav>
            <ul className="pagination justify-content-center m-0 align-middle">
                <li className={"page-item" + (page <= 0 ? " disabled" : "")}>
                    <a className="page-link"  tabIndex="-1" href={"#"} aria-disabled={page <= 0} onClick={(evt) => {
                        goToPage(evt,0);
                    }}><CaretLeft /><CaretLeft /></a>
                </li>
                <li className={"page-item" + (page <= 0 ? " disabled" : "")}>
                    <a className="page-link"  tabIndex="-1" href={"#"} aria-disabled={page <= 0} onClick={(evt) => {
                        goToPage(evt, page - 1);
                    }}><CaretLeft /></a>
                </li>

                <li className="page-item disabled"><a className="page-link" href="#">{page + 1}/{maxPage + 1}</a></li>

                <li className={"page-item" + (page >= maxPage ? " disabled": "")}>
                    <a className="page-link" href="#" aria-disabled={page >= maxPage} onClick={(evt) => {
                        goToPage(evt, page + 1);
                    }}><CaretRight /></a>
                </li>
                <li className={"page-item" + (page >= maxPage ? " disabled": "")}>
                    <a className="page-link" href="#" aria-disabled={page >= maxPage} onClick={(evt) => {
                        goToPage(evt, maxPage);
                    }}><CaretRight /><CaretRight /></a>
                </li>
            </ul>
        </nav>
    );
}

export default Pagination;
const endPoint = process.env.REACT_APP_endPoint + "/managers" || 'https://assetmanagement.fesg.ldit.be/api/v1/managers';

export async function getBuildings(siteId = null, page, sortBy, asc, token, setToken, setUser) {
    const url = new URL(endPoint + '/buildings');

    let search = {
        sortBy,
        asc
    }

    if (page !== null && page !== undefined) {
        search.page = page;
    }

    if (siteId) {
        search.siteId = siteId;
    }

    url.search = new URLSearchParams(search);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getMaxPage(siteId = null, token, setToken, setUser) {
    const url = new URL(endPoint + '/buildings/maxPage');

    if (siteId) {
        url.search = new URLSearchParams({
            siteId
        });
    }

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getBuilding(buildingId, token, setToken, setUser) {
    const url = new URL(endPoint + '/buildings/' + buildingId);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function createBuilding(name, siteId, description = null, token, setToken, setUser) {
    const url = new URL(endPoint + '/buildings');

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            name,
            siteId,
            description
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}
import React, {Fragment} from 'react';
import {Redirect, Switch} from "react-router-dom";
import Navbar from "../technicians/Navbar";
import PrivateRoute from "../components/PrivateRoute";
import ChecklistsAll from "./checklists/ChecklistsAll";
import MyMaintenanceAll from "./myMaintenance/MyMaintenanceAll";

function TechnicianApp() {
    return (
        <Fragment>
            <Navbar />
            <Switch>
                <PrivateRoute path={'/checklists'}>
                    <ChecklistsAll />
                </PrivateRoute>
                <PrivateRoute path={'/myMaintenance'}>
                    <MyMaintenanceAll />
                </PrivateRoute>
                <PrivateRoute path={'*'}>
                    <Redirect to={'/checklists'} />
                </PrivateRoute>
            </Switch>
        </Fragment>
    );
}

export default TechnicianApp;

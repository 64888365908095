import React from "react";
import SortableHeader from "../../components/SortableHeader";
import {Link} from "react-router-dom";

function ViewSites({sites}) {
    return (
        <div className="table-responsive mt-2" style={{
            borderRadius: '10px'
        }} >
            <table className="table table-light table-striped">
                <caption>Sites</caption>
                <thead className="text-light">
                <tr style={{background: '#6d7fcc'}}>
                    <SortableHeader text={"Name"} tableName={"name"}/>
                    <SortableHeader text={"Description"} tableName={"description"}/>
                    <SortableHeader text={"Stamp"} tableName={"createdAt"}/>
                </tr>
                </thead>
                <tbody>
                {sites.map(site => {
                    return (
                        <tr key={site.id}>
                            <td><Link to={'/sites/' + site.id}>{site.name}</Link></td>
                            <td>{site.description}</td>
                            <td>{site.createdAt}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default ViewSites;
import React, {useState, useContext} from "react";
import logo from "../img/login.jpg";
import {AuthContext} from "../contexts/AuthContext";
import {getCumulio, login} from "../models/auth";
import Error from "./Error";

function Login() {
    const {setUser, setToken, setManager, setCumulio} = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);

    async function submit(evt) {
        evt.preventDefault();
        setErrors([]);

        try {
            const response = await login(email, password);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                const token = response.token;
                localStorage.setItem('token', token);
                setManager(response.manager);
                if (response.manager) {
                    const response = await getCumulio(token, setToken, setUser);
                    setCumulio(response);
                }
                setToken(response.token);
                setUser(response.user);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end."]);
        }
    }

    return (
        <div className={"row w-50 mx-auto"} style={{height: 'max-content'}}>
            <div className={"col mt-5 border-right text-center p-0"} style={{backgroundColor: "darkgrey"}}>
                <img src={logo} style={{width: '100%'}}/>
            </div>
            <div className={"col mt-5 border p-3 bg-white align-items-center d-flex"}>
                <div className={"flex-fill"}>
                    {errors.map((error, index) => <Error key={index} message={error} />)}
                    <form onSubmit={submit}>
                        <div className="form-group">
                            <label htmlFor="email">Email address:</label>
                            <input type="email" className={"form-control"} id={"email"} value={email} required={true} onChange={(evt) => {
                                setEmail(evt.target.value);
                            }} placeholder={"Email address"}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" className={"form-control"} id={"password"} value={password} required={true} onChange={(evt) => {
                                setPassword(evt.target.value);
                            }} placeholder={"Password"}/>
                        </div>
                        <button type={"submit"} className={"btn btn-primary"}>Sign in</button>
                    </form>
                </div>

            </div>
        </div>
    );

}

export default Login;
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import {getAsset} from "../models/assets";
import {SortContext} from "../../contexts/SortContext";
import {getChecklists, getMaxPage} from "../models/checklists";
import ViewChecklists from "../checklists/ViewChecklists";

function AssetsOne() {
    const [asset, setAsset] = useState({});
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('createdAt');
    const [asc, setAsc] = useState(true);
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [checklists, setChecklists] = useState([]);

    const {token, setToken, setUser} = useContext(AuthContext);

    const {assetId} = useParams();

    useEffect(() => {
        (async () => {
            await fetchMaxPage();
            await fetchAsset();
        })();
    }, []);

    useEffect(() => {
        (async() => {
            await fetchChecklists();
        })();
    }, [page, sortBy, asc]);

    async function fetchMaxPage() {
        setErrors([]);

        try {
            const response = await getMaxPage(assetId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setMaxPage(response.maxPage);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to get max page."]);
        }
    }
    async function fetchChecklists() {
        setErrors([]);
        setLoading(true);
        try {
            const response = await getChecklists(assetId, page, sortBy, asc, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setChecklists(response.checklists);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch checklists."]);
        }
        setLoading(false);
    }

    async function fetchAsset() {
        setLoading(true);

        try {
            const response = await getAsset(assetId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors((prevState) => {
                    return [...prevState, response.errors];
                });
            }
            else {
                setAsset(response.asset);
            }
        }
        catch (e) {
            console.error(e);
            setErrors((prevState) => {
                const newState = [...prevState];
                newState.push("Failed to reach back end to fetch building.")
                return newState;
            });
        }

        setLoading(false);
    }

    return (
        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
            <h1>{asset.name}</h1>
            {errors.map((error, index) => <Error key={index} message={error} />)}
            {loading ? <Spinner /> :
                <Fragment>

                    <SortContext.Provider value={{
                        sortBy: sortBy,
                        asc: asc,
                        setSortBy: setSortBy,
                        setAsc: setAsc
                    }}>
                        <ViewChecklists checklists={checklists} fromAsset={true}/>
                    </SortContext.Provider>
                    <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                </Fragment>
            }
        </div>
    );
}

export default AssetsOne;
import React, {useContext, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {updateCheckListItem} from "../models/assets";

function QuestionRow({subGroup, question, weekly: initialWeekly, yearly: initialYearly, custom: initialCustom, id}) {
    const [custom, setCustom] = useState(initialCustom);
    const [weekly, setWeekly] = useState(initialWeekly);
    const [yearly, setYearly] = useState(initialYearly);

    const {token, setToken, setUser} = useContext(AuthContext);

    async function changeCustom(evt) {
        setCustom(evt.target.checked);
        try {
            await updateCheckListItem(id, {
                custom: evt.target.checked,
                weekly, yearly
            }, token, setToken, setUser);
        }
        catch (e) {
            console.error(e);
        }
    }
    async function changeWeekly(evt) {
        setWeekly(evt.target.checked);
        try {
            await updateCheckListItem(id, {
                custom,
                weekly: evt.target.checked,
                yearly
            }, token, setToken, setUser);
        }
        catch (e) {
            console.error(e);
        }
    }
    async function changeYearly(evt) {
        setYearly(evt.target.checked);
        try {
            await updateCheckListItem(id, {
                custom, weekly,
                yearly: evt.target.checked
            }, token, setToken, setUser);
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <tr>
            <td>{subGroup}</td>
            <td>{question}</td>
            <td><input type="checkbox" checked={weekly} onChange={changeWeekly}/></td>
            <td><input type="checkbox" checked={yearly} onChange={changeYearly}/></td>
            <td><input type="checkbox" checked={custom} onChange={changeCustom}/></td>
        </tr>
    )
}

export default QuestionRow;
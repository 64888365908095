import React, {useState} from "react";

function AddTechnician({addTechnician}) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');


    async function submit(evt) {
        evt.preventDefault();
        window.$('#addTechnicianModal').modal('hide');
        window.$('#addTechnicianModal').on('hidden.bs.modal', async (e) => {
            window.$('#addTechnicianModal').off('hidden.bs.modal');
            await addTechnician(firstName, lastName, email, description);
        });
    }

    return (
        <div>
            <button type={"button"} className={"btn btn-success"} data-toggle={"modal"} data-target="#addTechnicianModal">Add a technician</button>
            <div className="modal fade" id="addTechnicianModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={submit}>

                        <div className="modal-header">
                            <h5 className="modal-title">Add a technician</h5>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="firstName">First name:</label>
                                    <input type="text" id={"firstName"} value={firstName} className={"form-control"} required={true} placeholder={"First name"} onChange={(evt) => {
                                        setFirstName(evt.target.value);
                                    }}/>
                                </div>
                                <div className="form-group col">
                                    <label htmlFor="lastName">Last name:</label>
                                    <input type="text" id={"lastName"} value={lastName} className={"form-control"} required={true} placeholder={"Last name"} onChange={(evt) => {
                                        setLastName(evt.target.value);
                                    }}/>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email address:</label>
                                <input type="email" id={"email"} value={email} className={"form-control"} required={true} placeholder={"Email address"} onChange={(evt) => {
                                    setEmail(evt.target.value);
                                }}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="description">Description:</label>
                                <textarea id={"description"} value={description} className={"form-control"} required={false} placeholder={"Site description"} onChange={(evt) => {
                                    setDescription(evt.target.value);
                                }}/>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type={"submit"} className={"btn btn-success"}>Add technician</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTechnician;
const endPoint = process.env.REACT_APP_endPoint + "/technicians" || 'https://assetmanagement.fesg.ldit.be/api/v1/technicians';

export async function getSites(token, setToken, setUser) {
    const url = new URL(endPoint + '/sites');

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}
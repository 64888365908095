import React from "react";
import Trash from "../../components/Trash";

function DeleteAsset({assetId, removeAsset}) {
    async function onSubmit(evt) {
        evt.preventDefault();
        await removeAsset(assetId);
    }
    return (
        <form onSubmit={onSubmit} className={"d-inline"}>
            <button type={"submit"} className={"btn btn-sm btn-danger"}><Trash /></button>
        </form>
    );
}

export default DeleteAsset;
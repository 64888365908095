import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {Route, Switch} from "react-router-dom";
import Pagination from "../../components/Pagination";
import {AuthContext} from "../../contexts/AuthContext";
import {getMaxPage, getChecklists} from "../models/checklists";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import ChecklistsOne from "./ChecklistsOne";
import ViewChecklists from "./ViewChecklists";
import {SortContext} from "../../contexts/SortContext";

function CheckListsAll() {
    const [checklists, setChecklists] = useState([]);
    const [maxPage, setMaxPage] = useState(0);
    const [page, setPage] = useState(0);
    const [errors, setErrors] = useState([0]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState("createdAt");
    const [asc, setAsc] = useState(true);
    const isMounted = useRef(true);

    const {token, setToken, setUser, cumulio} = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchMaxPage();
            setLoading(false);
        })();

        window.Cumulio.addDashboard({
            dashboardId: '1894f25b-a9e1-4359-83b6-0245df97fa78',
            container: '#checkListsDashboard',
            key: cumulio.id,
            token: cumulio.token
        });
        return () => {
            isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        (async() => {
            await fetchChecklists();
        })();
    }, [page, sortBy, asc]);

    async function fetchMaxPage() {
        if (isMounted.current) {
            setErrors([]);
        }

        try {
            const response = await getMaxPage(null, token, setToken, setUser);
            if (isMounted.current) {
                if (response.errors.length > 0) {
                    setErrors(response.errors);
                }
                else {
                    setMaxPage(response.maxPage);
                }
            }
        }
        catch (e) {
            console.error(e);
            if (isMounted.current) {
                setErrors(["Failed to reach back end to get max page."]);
            }
        }
    }
    async function fetchChecklists() {
        if (isMounted.current) {
            setErrors([]);
            setLoading(true);
        }
        try {
            const response = await getChecklists(null, page, sortBy, asc, token, setToken, setUser);
            if (isMounted.current) {
                if (response.errors.length > 0) {
                    setErrors(response.errors);
                }
                else {
                    setChecklists(response.checklists);
                }
            }
        }
        catch (e) {
            console.error(e);
            if (isMounted.current) {
                setErrors(["Failed to reach back end to fetch checklists."]);
            }
        }
        if (isMounted.current) {
            setLoading(false);
        }
    }

    return (
        <SortContext.Provider value={{
            sortBy: sortBy,
            asc: asc,
            setSortBy: setSortBy,
            setAsc: setAsc
        }}>
            <div className={"col mx-auto mt-2"} style={{maxHeight: '95%', overflow: "auto"}}>
                {errors.map((error, index) => <Error key={index} message={error} />)}
                <div id={"checkListsDashboard"}></div>
                <Switch>
                    <Route path={'/checklists/:checklistId'}>
                        <ChecklistsOne fetchChecklists={fetchChecklists}/>
                    </Route>
                    <Route path={'/checklists'}>
                        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
                            {loading ? <Spinner /> :
                                <Fragment>
                                    <ViewChecklists checklists={checklists}/>
                                    <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                                </Fragment>
                            }
                        </div>
                    </Route>
                </Switch>

            </div>

        </SortContext.Provider>
    );
}

export default CheckListsAll;
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import AddSite from "./AddSite";
import ViewSites from "./ViewSites";
import {Switch, Route} from "react-router-dom";
import SitesOne from "./SitesOne";
import Pagination from "../../components/Pagination";
import {getSites, getMaxPage, createSite} from "../models/sites";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import {SortContext} from "../../contexts/SortContext";

function SitesAll() {
    const [sites, setSites] = useState([]);
    const [maxPage, setMaxPage] = useState(0);
    const [page, setPage] = useState(0);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState("createdAt");
    const [asc, setAsc] = useState(true);
    const isMounted = useRef(true);

    const {token, setToken, setUser, cumulio} = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            await fetchMaxPage();
        })();
        window.Cumulio.addDashboard({
            dashboardId: '1be65cca-df6c-4bcd-a88e-4b77efa6c683',
            container: '#sitesDashboard',
            key: cumulio.id,
            token: cumulio.token
        });

        return () => {
            isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        (async() => {
            await fetchSites();
        })();
    }, [page, sortBy, asc]);

    async function fetchMaxPage(){
        if (isMounted.current) {
            setErrors([]);
        }

        try {
            const response = await getMaxPage(token, setToken, setUser);
            if (isMounted.current) {
                if (response.errors.length > 0) {
                    setErrors(response.errors);
                }
                else {
                    setMaxPage(response.maxPage);
                }
            }
        }
        catch (e) {
            console.error(e);
            if (isMounted.current) {
                setErrors(["Failed to reach back end to get max page."]);
            }
        }
    }
    async function fetchSites() {
        if (isMounted.current) {
            setErrors([]);
            setLoading(true);
        }

        try {
            const response = await getSites(page, sortBy, asc, token, setToken, setUser);
            if (isMounted.current) {
                if (response.errors.length > 0) {
                    setErrors(response.errors);
                }
                else {
                    setSites(response.sites);
                }
            }

        }
        catch (e) {
            console.error(e);
            if (isMounted.current) {
                setErrors(["Failed to reach back end to fetch sites."]);
            }
        }

        if (isMounted.current) {
            setLoading(false);
        }
    }

    async function addSite(name, description) {
        setErrors([]);
        setLoading(true);

        try {
            const response = await createSite(name, description, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                await fetchMaxPage();
                await fetchSites();
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to create site."]);
        }

        setLoading(false);
    }

    return (
            <div className={"mx-auto mt-2 col"} style={{maxHeight: '95%', overflow: "auto"}}>
                {errors.map((error, index) => <Error key={index} message={error} />)}
                <div id={"sitesDashboard"}></div>

                <Switch>
                    <Route path={'/sites'} exact={true}>
                        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
                            <AddSite addSite={addSite}/>

                            {loading ? <Spinner /> :
                            <Fragment>
                                <SortContext.Provider value={{
                                    sortBy: sortBy,
                                    asc: asc,
                                    setSortBy: setSortBy,
                                    setAsc: setAsc
                                }}>
                                    <ViewSites sites={sites}/>
                                </SortContext.Provider>
                                <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                            </Fragment>
                            }
                        </div>
                    </Route>
                    <Route path={'/sites/:siteId'}>
                        <SitesOne />
                    </Route>
                </Switch>
            </div>
    );
}

export default SitesAll;
import React from "react";
import logo from "../img/logo.png";
import {NavLink} from "react-router-dom";

function Navbar() {
    return (
        <nav className="navbar navbar-dark d-block p-0 sticky-top" style={{width: '250px', height: '100vh', backgroundColor: '#7386D5'}}>
            <div style={{backgroundColor: '#6d7fcc', width: '100%'}} className={"pl-4 py-2"}>
                <img src={logo} style={{height: '80px'}} alt=""/>
            </div>
            <hr className={"m-0"}/>
            <ul className="navbar-nav pl-4 py-2">
                <li className="nav-item">
                    <NavLink to={'/checklists'} className="nav-link" activeClassName={"active"}>
                        <svg style={{marginRight: '0.5em'}} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-card-checklist"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path fillRule="evenodd"
                                  d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        Checklists</NavLink>
                    <NavLink to={'/myMaintenance'} className="nav-link" activeClassName={"active"}>
                        <svg style={{marginRight: '0.5em'}} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-bookmark-check"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                            <path fillRule="evenodd"
                                  d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        My maintenance</NavLink>
                </li>
            </ul>

        </nav>
    );
}

export default Navbar;
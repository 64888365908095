const endPoint = process.env.REACT_APP_endPoint + "/managers" || 'https://assetmanagement.fesg.ldit.be/api/v1/managers';

export async function getQuestions(token, setToken, setUser) {
    const url = new URL(endPoint + '/questions');

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getAssets(buildingId = null, technicianId = null, page, sortBy, asc, token, setToken, setUser) {
    const url = new URL(endPoint + '/assets');

    let search = {
        sortBy,
        asc
    }

    if (page !== null && page !== undefined) {
        search.page = page;
    }

    if (buildingId) {
        search.buildingId = buildingId;
    }
    if (technicianId) {
        search.technicianId = technicianId;
    }

    url.search = new URLSearchParams(search);


    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getAsset(assetId, token, setToken, setUser) {
    const url = new URL(endPoint + '/assets/' + assetId);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getCheckListItems(assetId, token, setToken, setUser) {
    const url = new URL(endPoint + '/assets/' + assetId + '/checkListItems');

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function deleteAsset(assetId, token, setToken, setUser) {
    const url = new URL(endPoint + '/assets/' + assetId);

    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function updateCheckListItem(checkListItemId, {custom, weekly, yearly}, token, setToken, setUser) {
    const url = new URL(endPoint + '/assets/checkListItems/' + checkListItemId);

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            custom,
            weekly,
            yearly
        })
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function updateTechnicianId(assetId, technicianId, token, setToken, setUser) {
    const url = new URL(endPoint + '/assets/' + assetId + '/technicianId');

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            technicianId
        })
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getMaxPage(buildingId = null, technicianId = null, token, setToken, setUser) {
    const url = new URL(endPoint + '/assets/maxPage');

    let search = {};

    if (buildingId) {
        search.buildingId = buildingId;
    }
    if (technicianId) {
        search.technicianId = technicianId;
    }

    url.search = new URLSearchParams(search);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function createAsset(name, buildingId, description = null, answers, token, setToken, setUser) {
    const url = new URL(endPoint + '/assets');

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            name,
            buildingId,
            description,
            answers
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}
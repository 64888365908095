const endPoint = process.env.REACT_APP_endPoint + "/technicians" || 'https://assetmanagement.fesg.ldit.be/api/v1/technicians';

export async function getChecklists(page, sortBy, asc, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists');

    let search = {
        sortBy,
        asc
    }

    if (page !== null && page !== undefined) {
        search.page = page;

    }

    url.search = new URLSearchParams(search);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getMaxPage(token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/maxPage');

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function createChecklist(assetId, type, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists');

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            assetId,
            type
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getChecklist(checklistId, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/' + checklistId);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getCheckListItems(checklistId, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/' + checklistId + '/items');

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function updateCheckListItemRemarks(checkListItemId, remarks, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/checkListItems/' + checkListItemId + "/remarks");

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            remarks
        })
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function updateCheckListItemStatus(checkListItemId, status, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/checkListItems/' + checkListItemId + "/status");

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            status
        })
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function updateCheckListItemOk(checkListItemId, ok, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/checkListItems/' + checkListItemId + "/ok");

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            ok
        })
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function updateCheckListItemNa(checkListItemId, na, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/checkListItems/' + checkListItemId + "/na");

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            na
        })
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function updateChecklist(checkListId, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/' + checkListId);

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}
import React, {useContext, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {updateCheckListItemOk, updateCheckListItemRemarks, updateCheckListItemNa, updateCheckListItemStatus} from "../models/checklists";

function QuestionRow({subGroup, ok: initialOk, na: initialNa, question, remarks: initialRemarks, statusId: initialStatusId, id, done, statuses}) {
    const [ok, setOk] = useState(initialOk);
    const [na, setNa] = useState(initialNa);
    const [remarks, setRemarks] = useState(initialRemarks === null ? "": initialRemarks);
    const [statusId, setStatusId] = useState(initialStatusId === null ? "": initialStatusId);

    const {token, setToken, setUser} = useContext(AuthContext);

    async function changeOk(evt) {
        setOk(evt.target.checked);
        try {
            await updateCheckListItemOk(id, evt.target.checked, token, setToken, setUser);
        }
        catch (e) {
            console.error(e);
        }
    }

    async function changeNa(evt) {
        setNa(evt.target.checked);
        try {
            await updateCheckListItemNa(id, evt.target.checked, token, setToken, setUser);
            if (evt.target.checked) {
                setOk(false);
                setRemarks("");
                setStatusId("");
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    async function changeRemarks(evt) {
        setRemarks(evt.target.value);
        try {
            await updateCheckListItemRemarks(id, evt.target.value, token, setToken, setUser);
        }
        catch (e) {
            console.error(e);
        }
    }

    async function changeStatus(evt) {
        setStatusId(evt.target.value);
        try {
            await updateCheckListItemStatus(id, evt.target.value, token, setToken, setUser);
        }
        catch (e) {
            console.error(e);
        }
    }

    let style = {

    };

    if (na) {
        style.backgroundColor = "darkgrey";
    }
    return (
        <tr style={style}>
            <td>{subGroup}</td>
            <td>{question}</td>
            <td><input disabled={done || na} type="checkbox" checked={ok} onChange={changeOk}/></td>
            <td><input disabled={done} type="checkbox" checked={na} onChange={changeNa}/></td>
            <td><select disabled={done || na} className={"form-control"} value={statusId} onChange={changeStatus}>
                <option value=""></option>
                {statuses.map((value, index) => {
                    return <option value={value.id} key={index}>{value.status}</option>
                })}
            </select></td>
            <td><textarea disabled={done || na} className={"form-control"} value={remarks} onChange={changeRemarks}/></td>
        </tr>
    )
}

export default QuestionRow;
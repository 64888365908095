const endPoint = process.env.REACT_APP_endPoint + "/managers" || 'https://assetmanagement.fesg.ldit.be/api/v1/managers';

export async function getChecklists(assetId = null, page, sortBy, asc, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists');

    let search = {
        sortBy,
        asc
    }

    if (page !== null && page !== undefined) {
        search.page = page;
    }


    if (assetId) {
        search.assetId = assetId;
    }

    url.search = new URLSearchParams(search);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getMaxPage(assetId = null, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/maxPage');

    if (assetId) {
        url.search = new URLSearchParams({
            assetId
        });
    }

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getChecklist(checklistId, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/' + checklistId);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getCheckListItems(checklistId, token, setToken, setUser) {
    const url = new URL(endPoint + '/checklists/' + checklistId + '/items');

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}
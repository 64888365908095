import React, {useContext} from "react";
import ASC from "./ASC";
import DESC from "./DESC";
import Dash from "./Dash";
import {SortContext} from "../contexts/SortContext";

function SortableHeader({tableName, text}) {
    const {sortBy, asc, setSortBy, setAsc} = useContext(SortContext);

    let toReturn;

    if (tableName === sortBy) {
        if (asc) {
            toReturn = <ASC/>
        }
        else {
            toReturn = <DESC/>
        }
    }
    else {
        toReturn = <Dash/>
    }

    return <th onClick={(evt) => {
        if (sortBy !== tableName) {
            setSortBy(tableName);
        }
        else {
            if (asc) {
                setAsc(false);
            }
            else {
                setAsc(true);
            }
        }
    }} style={{
        cursor: "pointer"
    }}>{text} {toReturn}</th>;
}

export default SortableHeader;
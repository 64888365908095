import {createContext} from "react";

export const AuthContext = createContext({
    token: null,
    user: {},
    setUser: () => {},
    setToken: () => {},
    manager: false,
    setManager: () => {},
    cumulio: null,
    setCumulio: () => {}
});
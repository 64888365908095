import React from "react";
import {Link} from "react-router-dom";
import SortableHeader from "../../components/SortableHeader";
import DeleteAsset from "./DeleteAsset";
import Pencil from "../../components/Pencil";

function ViewAssets({assets, removeAsset, fromBuilding = false}) {
    return (
        <div className="table-responsive mt-2" style={{
            borderRadius: '10px'
        }} >
            <table className="table table-light table-striped">
                <caption>Assets</caption>
                <thead className="text-light" >
                <tr style={{background: '#6d7fcc'}}>
                    <SortableHeader tableName={"name"} text={"Name"} />
                    {!fromBuilding && <SortableHeader tableName={"building"} text={"Building"} />}
                    <SortableHeader tableName={"description"} text={"Description"} />
                    <SortableHeader tableName={"createdAt"} text={"Stamp"} />
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {assets.map(asset => {
                    return (
                        <tr key={asset.id}>
                            <td><Link to={'/assets/' + asset.id}>{asset.name}</Link></td>
                            {!fromBuilding && <td><Link to={'/buildings/' + asset.buildingId}>{asset.building}</Link></td>}
                            <td>{asset.description}</td>
                            <td>{asset.createdAt}</td>
                            <td>
                                <Link to={'/assets/' + asset.id + '/edit'}><button className={"btn btn-sm btn-primary mr-1"}><Pencil /></button></Link>
                                <DeleteAsset assetId={asset.id} removeAsset={removeAsset}/>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default ViewAssets;
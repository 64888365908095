import React, {Fragment} from 'react';
import Navbar from "./Navbar";
import SitesAll from "./sites/SitesAll";
import BuildingsAll from "./buildings/BuildingsAll";
import PrivateRoute from "../components/PrivateRoute";

import {
    Switch,
    Redirect
} from "react-router-dom";
import AssetsAll from "./assets/AssetsAll";
import TechniciansAll from "./technicians/TechniciansAll";
import CheckListsAll from "./checklists/ChecklistsAll";

function ManagerApp() {
    return (
        <Fragment>
            <Navbar />
            <Switch>
                <PrivateRoute path={'/sites'}>
                    <SitesAll />
                </PrivateRoute>
                <PrivateRoute  path={'/buildings'}>
                    <BuildingsAll />
                </PrivateRoute>
                <PrivateRoute  path={'/assets'}>
                    <AssetsAll />
                </PrivateRoute>
                <PrivateRoute  path={'/technicians'}>
                    <TechniciansAll />
                </PrivateRoute>
                <PrivateRoute  path={'/checklists'}>
                    <CheckListsAll />
                </PrivateRoute>
                <PrivateRoute path={'*'}>
                    <Redirect to={'/sites'} />
                </PrivateRoute>
            </Switch>
        </Fragment>
    );
}

export default ManagerApp;

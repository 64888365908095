import React from "react";

function QuestionRow({subGroup, ok, na, question, remarks, status, id, done}) {
    let style = {

    };

    if (na) {
        style.backgroundColor = "darkgrey";
    }
    return (
        <tr style={style}>
            <td>{subGroup}</td>
            <td>{question}</td>
            <td><input disabled={true} type="checkbox" checked={ok} /></td>
            <td><input disabled={true} type="checkbox" checked={na} /></td>
            <td>{status}</td>
            <td>{remarks}</td>
        </tr>
    )
}

export default QuestionRow;
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import {getBuilding} from "../models/buildings";
import Pagination from "../../components/Pagination";
import ViewAssets from "../assets/ViewAssets";
import {createAsset, deleteAsset, getAssets, getMaxPage} from "../models/assets";
import {SortContext} from "../../contexts/SortContext";
import AddAsset from "../assets/AddAsset";

function BuildingsOne() {
    const [building, setBuilding] = useState({});
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('createdAt');
    const [asc, setAsc] = useState(true);
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [assets, setAssets] = useState([]);

    const {token, setToken, setUser} = useContext(AuthContext);

    const {buildingId} = useParams();

    useEffect(() => {
        (async () => {
            await fetchMaxPage();
            await fetchBuilding();
        })();
    }, []);

    useEffect(() => {
        (async() => {
            await fetchAssets();
        })();
    }, [page, sortBy, asc]);

    async function fetchMaxPage() {
        setErrors([]);

        try {
            const response = await getMaxPage(buildingId, null, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setMaxPage(response.maxPage);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to get max page."]);
        }
    }
    async function fetchAssets() {
        setErrors([]);
        setLoading(true);
        try {
            const response = await getAssets(buildingId, null, page, sortBy, asc, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setAssets(response.assets);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch assets."]);
        }
        setLoading(false);
    }

    async function addAsset(name, buildingId, description, answers) {
        setErrors([]);
        setLoading(true);

        try {
            const response = await createAsset(name, buildingId, description, answers, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                await fetchMaxPage();
                await fetchAssets();
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to create asset."]);
        }

        setLoading(false);
    }

    async function removeAsset(assetId) {
        setErrors([]);
        setLoading(true);

        try {
            const response = await deleteAsset(assetId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                await fetchMaxPage();
                await fetchAssets();
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to remove asset."]);
        }

        setLoading(false);
    }

    async function fetchBuilding() {
        setLoading(true);

        try {
            const response = await getBuilding(buildingId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors((prevState) => {
                    return [...prevState, response.errors];
                });
            }
            else {
                setBuilding(response.building);
            }
        }
        catch (e) {
            console.error(e);
            setErrors((prevState) => {
                const newState = [...prevState];
                newState.push("Failed to reach back end to fetch building.")
                return newState;
            });
        }

        setLoading(false);
    }

    return (
        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
            <h1>{building.name}</h1>
            {errors.map((error, index) => <Error key={index} message={error} />)}
            <AddAsset addAsset={addAsset} buildingId={buildingId}/>

            {loading ? <Spinner /> :
                <Fragment>

                    <SortContext.Provider value={{
                        sortBy: sortBy,
                        asc: asc,
                        setSortBy: setSortBy,
                        setAsc: setAsc
                    }}>
                        <ViewAssets removeAsset={removeAsset} assets={assets} fromBuilding={true}/>
                    </SortContext.Provider>
                    <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                </Fragment>
            }
        </div>
    );
}

export default BuildingsOne;
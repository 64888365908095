import React, {useEffect, useState} from "react";
import {AuthContext} from "./contexts/AuthContext";
import Login from "./components/Login";
import ManagerApp from "./managers/ManagerApp";
import TechnicianApp from "./technicians/TechnicianApp";
import {Switch, Route, Redirect, useLocation, useHistory} from "react-router-dom";
import ResetPassword from "./components/ResetPassword";
import {getCumulio, getUser} from "./models/auth";

function App() {
    const [user, setUser] = useState({});
    const [cumulio, setCumulio] = useState({});
    const [token, setToken] = useState(null);
    const [manager, setManager] = useState(false);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const tokenFromLocalStorage = localStorage.getItem('token');
            if (tokenFromLocalStorage) {
                try {
                    const response = await getUser(tokenFromLocalStorage, setToken, setUser);
                    if (response.errors.length <= 0) {
                        setManager(response.manager);
                        if (response.manager) {
                            const response = await getCumulio(tokenFromLocalStorage, setToken, setUser);
                            setCumulio(response);
                        }
                        setUser(response.user);
                        setToken(tokenFromLocalStorage);
                        history.replace(location.pathname);
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        })();
    }, []);

    return (
        <AuthContext.Provider value={{
            user: user,
            token: token,
            setToken: setToken,
            setUser: setUser,
            manager: manager,
            setManager: setManager,
            cumulio: cumulio,
            setCumulio: setCumulio
        }}>
                <div className={"container-fluid bg-light p-0 row"} style={{height: '100vh'}}>
                    {token ? (manager ? <ManagerApp /> : <TechnicianApp />)
                        : (
                            <Switch>
                                <Route path={'/login'}>
                                    <Login />
                                </Route>
                                <Route path={'/resetPassword/:token'} exact={true}>
                                    <ResetPassword />
                                </Route>
                                <Route path={'*'}>
                                    <Redirect to={'/login'} />
                                </Route>
                            </Switch>
                            )
                    }
                </div>
        </AuthContext.Provider>
    )
}

export default App;
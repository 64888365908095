import React, {Fragment, useContext, useEffect, useState} from "react";
import {getChecklist, getCheckListItems} from "../models/checklists";
import {useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import CheckListGroupRow from "./CheckListGroupRow";
import QuestionRow from "./QuestionRow";

function ChecklistsOne() {
    const [checklist, setChecklist] = useState({});
    const [errors, setErrors] = useState([]);
    const [checkListGroups, setCheckListGroups] = useState([]);
    const [loading, setLoading] = useState(false);

    const {token, setToken, setUser} = useContext(AuthContext);

    const {checklistId} = useParams();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchChecklist();
            await fetchCheckListQuestions();
            setLoading(false);
        })();
    }, []);

    async function fetchChecklist() {
        try {
            const response = await getChecklist(checklistId, token, setToken, setUser);

            if (response.errors.length > 0) {
                setErrors((prevState) => {
                    return [...prevState, response.errors];
                });
            }
            else {
                setChecklist(response.checklist);
            }
        }
        catch (e) {
            console.error(e);
            setErrors((prevState) => {
                const newState = [...prevState];
                newState.push("Failed to reach back end to fetch checklist.")
                return newState;
            });
        }
    }

    async function fetchCheckListQuestions() {
        try {
            const response = await getCheckListItems(checklistId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors((prevState) => {
                    return [...prevState, response.errors];
                });
            }
            else {
                setCheckListGroups(response.checkListItems);
            }
        }
        catch (e) {
            setErrors((prevState) => {
                const newState = [...prevState];
                newState.push("Failed to reach back end to fetch checklist items.")
                return newState;
            });
        }
    }

    return (
        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
            <h1>{checklist.name}</h1>
            {errors.map((error, index) => <Error key={index} message={error} />)}

            {loading ? <Spinner /> :
                <Fragment>
                    <div className={"table-responsive"} style={{
                        borderRadius: '10px'
                    }} >
                        <table className={"table table-sm table-light table-striped"}>
                            <caption>Checklist items</caption>
                            <thead className={"text-light"}>
                            <tr style={{background: '#6d7fcc'}}>
                                <th>Component</th>
                                <th>Task</th>
                                <th>Check performed</th>
                                <th>N/A</th>
                                <th>Status</th>
                                <th>Remarks</th>
                            </tr>
                            </thead>
                            <tbody>
                            {checkListGroups.map((group, groupIndex) => {
                                return (
                                    <Fragment key={groupIndex}>
                                        <CheckListGroupRow checkListGroup={group.name}/>
                                        {group.subGroups.map((row, rowIndex) => {
                                            return (
                                                <QuestionRow done={checklist.done} question={row.question} ok={row.ok} na={row.na} remarks={row.remarks} status={row.status} subGroup={row.subGroup} id={row.id} key={rowIndex}/>
                                            )
                                        })}
                                    </Fragment>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </Fragment>
            }
        </div>
    );
}

export default ChecklistsOne;
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import {getSite} from "../models/sites";
import Pagination from "../../components/Pagination";
import ViewBuildings from "../buildings/ViewBuildings";
import {createBuilding, getBuildings, getMaxPage} from "../models/buildings";
import {SortContext} from "../../contexts/SortContext";
import AddBuilding from "../buildings/AddBuilding";

function SitesOne() {
    const [site, setSite] = useState({});
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('createdAt');
    const [asc, setAsc] = useState(true);
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [buildings, setBuildings] = useState([]);

    const {token, setToken, setUser} = useContext(AuthContext);

    const {siteId} = useParams();

    useEffect(() => {
        (async () => {
            await fetchMaxPage();
            await fetchSite();
        })();
    }, []);

    useEffect(() => {
        (async() => {
            await fetchBuildings();
        })();
    }, [page, sortBy, asc]);

    async function fetchMaxPage() {
        setErrors([]);

        try {
            const response = await getMaxPage(siteId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setMaxPage(response.maxPage);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to get max page."]);
        }
    }
    async function fetchBuildings() {
        setErrors([]);
        setLoading(true);
        try {
            const response = await getBuildings(siteId, page, sortBy, asc, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setBuildings(response.buildings);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch buildings."]);
        }
        setLoading(false);
    }
    async function addBuilding(name, siteId, description) {
        setErrors([]);
        setLoading(true);

        try {
            const response = await createBuilding(name, siteId, description, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                await fetchMaxPage();
                await fetchBuildings();
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to create building."]);
        }

        setLoading(false);
    }

    async function fetchSite() {
        setLoading(true);

        try {
            const response = await getSite(siteId, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors((prevState) => {
                    return [...prevState, response.errors];
                });
            }
            else {
                setSite(response.site);
            }
        }
        catch (e) {
            console.error(e);
            setErrors((prevState) => {
                const newState = [...prevState];
                newState.push("Failed to reach back end to fetch site.")
                return newState;
            });
        }

        setLoading(false);
    }

    return (
        <div className={"p-2 bg-white mt-1 border shadow-sm"}>
            <h1>{site.name}</h1>
            {errors.map((error, index) => <Error key={index} message={error} />)}
            <AddBuilding addBuilding={addBuilding} siteId={siteId}/>

            {loading ? <Spinner /> :
                <Fragment>

                    <SortContext.Provider value={{
                        sortBy: sortBy,
                        asc: asc,
                        setSortBy: setSortBy,
                        setAsc: setAsc
                    }}>
                    <ViewBuildings buildings={buildings} fromSite={true}/>
                    </SortContext.Provider>
                    <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                </Fragment>
            }
        </div>
    );
}

export default SitesOne;
const endPoint = process.env.REACT_APP_endPoint + "/managers" || 'https://assetmanagement.fesg.ldit.be/api/v1/managers';

export async function getTechnicians(page, sortBy, asc, token, setToken, setUser) {
    const url = new URL(endPoint + '/technicians');

    let search = {
        sortBy,
        asc
    }

    if (page !== null && page !== undefined) {
        search.page = page;

    }

    url.search = new URLSearchParams(search);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getMaxPage(token, setToken, setUser) {
    const url = new URL(endPoint + '/technicians/maxPage');

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function createTechnician(firstName, lastName, email, description, token, setToken, setUser) {
    const url = new URL(endPoint + '/technicians');

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            description
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function getTechnician(technicianId, token, setToken, setUser) {
    const url = new URL(endPoint + '/technicians/' + technicianId);

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });


    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}
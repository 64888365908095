import React, {useEffect, useState} from "react";

function AddSite({addSite}) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');


    async function submit(evt) {
        evt.preventDefault();
        window.$('#addSiteModal').modal('hide');
        window.$('#addSiteModal').on('hidden.bs.modal', async (e) => {
            window.$('#addSiteModal').off('hidden.bs.modal');
            await addSite(name, description);
        });
    }

    return (
        <div>
            <button type={"button"} className={"btn btn-success"} data-toggle={"modal"} data-target="#addSiteModal">Add a site</button>
            <div className="modal fade" id="addSiteModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={submit}>

                        <div className="modal-header">
                            <h5 className="modal-title">Add a site</h5>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input type="text" id={"name"} value={name} className={"form-control"} required={true} placeholder={"Site name"} onChange={(evt) => {
                                    setName(evt.target.value);
                                }}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description:</label>
                                <textarea id={"description"} value={description} className={"form-control"} required={false} placeholder={"Site description"} onChange={(evt) => {
                                    setDescription(evt.target.value);
                                }}/>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type={"submit"} className={"btn btn-success"}>Add site</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddSite;
import React, {useContext, useEffect, useState} from "react";
import {getQuestions} from "../models/assets";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import {getBuildings} from "../models/buildings";

function AddAsset({addAsset, buildingId: buildingIdFromParent}) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [buildingId, setBuildingId] = useState(buildingIdFromParent ? buildingIdFromParent.toString(): "");
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const {token, setToken, setUser} = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            await fetchQuestions();
            if (!buildingIdFromParent) {
                await fetchBuildings();
            }
        })();
    }, []);

    function submit(evt) {
        evt.preventDefault();
        window.$('#addAssetModal').modal('hide');
        window.$('#addAssetModal').on('hidden.bs.modal', async (e) => {
            window.$('#addAssetModal').off('hidden.bs.modal');
            await addAsset(name, buildingId, description, questions.map(value => {
                return value.value;
            }));
        });
    }

    async function fetchQuestions() {
        setErrors([]);
        setLoading(true);
        try {
            const response = await getQuestions(token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setQuestions(response.questions.map(value => {
                    return {
                        ...value,
                        value: ''
                    }
                }));
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch questions."]);
        }
        setLoading(false);
    }

    async function fetchBuildings() {
        setErrors([]);
        setLoading(true);
        try {
            const response = await getBuildings(null,null, "name", true, token, setToken, setUser);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setBuildings(response.buildings);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back end to fetch buildings."]);
        }
        setLoading(false);
    }

    function changeQuestion(questionId, index, value) {
        if (questionId === 4) {
            if (value === "16") {
                document.getElementById("question5Div").classList.remove("d-none");
            }
            else {
                document.getElementById("question5Div").classList.add("d-none");
                document.getElementById("question5").value = "";
            }
        }
        else if (questionId === 1) {
            if (value === "2") {
                document.getElementById("question2Div").classList.add("d-none");
                document.getElementById("question2").value = "";
                document.getElementById("question2").required = false;
            }
            else {
                document.getElementById("question2Div").classList.remove("d-none");
                document.getElementById("question2").required = true;
            }
        }

        setQuestions(prevState => {
            const newState = [...prevState];
            newState[index].value = value;
            return newState;
        });
    }

    return (
        <div>
            <button type={"button"} className={"btn btn-success"} data-toggle={"modal"} data-target="#addAssetModal">Add an asset</button>
            <div className="modal fade" id="addAssetModal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={submit}>

                            <div className="modal-header">
                                <h5 className="modal-title">Add an asset</h5>
                                <button type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {errors.map((error, index) => <Error key={index} message={error} />)}
                                <div className="form-row">
                                    <div className="form-group col">
                                        <label htmlFor="name">Name:</label>
                                        <input type="text" id={"name"} value={name} className={"form-control"} required={true} placeholder={"Asset name"} onChange={(evt) => {
                                            setName(evt.target.value);
                                        }}/>
                                    </div>
                                    {!buildingIdFromParent &&
                                    <div className="form-group col">
                                        <label htmlFor="building">Building:</label>
                                        <select id="building" value={buildingId} onChange={(evt) => {
                                            setBuildingId(evt.target.value);
                                        }} className={"form-control"} required={true}>
                                            <option value="">Choose a building</option>
                                            {buildings.map((building) => {
                                                return <option value={building.id}
                                                               key={building.id}>{building.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Description:</label>
                                    <textarea id={"description"} value={description} className={"form-control"} required={false} placeholder={"Asset description"} onChange={(evt) => {
                                        setDescription(evt.target.value);
                                    }}/>
                                </div>

                                <hr/>

                                {loading ? <Spinner /> :
                                    questions &&
                                    questions.map((question, index) => {
                                        return (
                                            <div className={"form-group " + (question.id === 5 ? "d-none" : "")} key={question.id} id={'question' + question.id + "Div"}>
                                                <label htmlFor={'question' + question.id}>{question.question}:</label>
                                                <select name={'question' + question.id} id={'question' + question.id} required={question.id !== 5} value={question.value} className={"form-control"} onChange={(evt) => {
                                                    changeQuestion(question.id, index, evt.target.value);
                                                }}>
                                                    <option value="">Select an answer</option>
                                                    {question.answers.map(answer => {
                                                        return <option key={'question' + question.id + 'answer' + answer.id} value={answer.id}>{answer.answer}</option>
                                                    })}
                                                </select>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type={"submit"} className={"btn btn-success"}>Add asset</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAsset;
import React from "react";
import SortableHeader from "../../components/SortableHeader";
import {Link} from "react-router-dom";

function ViewTechnicians({technicians}) {
    return (
        <div className="table-responsive mt-2" style={{
            borderRadius: '10px'
        }} >
            <table className="table table-light table-striped">
                <caption>Technicians</caption>
                <thead className="text-light" >
                <tr style={{background: '#6d7fcc'}}>
                    <SortableHeader text={"Name"} tableName={"fullName"} />
                    <SortableHeader text={"Email"} tableName={"email"} />
                    <SortableHeader text={"Description"} tableName={"description"} />
                    <SortableHeader text={"Stamp"} tableName={"createdAt"} />
                </tr>
                </thead>
                <tbody>
                {technicians.map(technician => {
                    return (
                        <tr key={technician.id}>
                            <td><Link to={'/technicians/' + technician.id}>{technician.fullName}</Link></td>
                            <td>{technician.email}</td>
                            <td>{technician.description}</td>
                            <td>{technician.createdAt}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default ViewTechnicians;